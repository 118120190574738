import * as React from "react"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seohp"
import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"

import ReimbursmentWhite from "../../images/hp/access/reimbursement-white.png"
import ReimbursementIcon from "../../images/hp/access/reimbursement.png"
import PapIcon from "../../images/hp/access/pap.png"
import CopayIcon from "../../images/hp/access/copay.png"

import FlashcardPDF from "../../pdfs/Specialty-Pharmacy-Locator-Flashcard.pdf"
import AkebiaCaresPDF from "../../pdfs/AkebiaCares-Copay-Coupon.pdf"
import EnrollmentFormPDF from "../../pdfs/AkebiaCares-Enrollment-Form.pdf"


import akbcares from "../../images/AkebiaCares-logo.png"

const AccessPage = ({ location }) => (
	<HPPLayout
		location={location}
		locationHostName={location.hostname}
		jobCode="PP-AUR-US-1407 (v7.0)"
		jobCodeDate="07/23"
		references={[
			<>Data on File 31, Akebia Therapeutics, Inc.</>]} >
		<Helmet>
			<html lang="en" />
			<link rel="canonical" href="https://www.auryxiahcp.com/hyperphosphatemia/patient-access" />
			<body className="hpp" id="hpp-hcp-access" />
		</Helmet>

		<Seo title="Patient Access (HCP)" description="AkebiaCares is your partner in helping patients access the medication they need. Learn about coverage options and affordability. Read Important Safety Information and Full Prescribing information on this page." />
		<section id="hero">
			<div className="flex-row">
				<div>
					<img style={{ maxWidth: "200px" }} src={akbcares} alt="AkebiaCares" />
				</div>
				<div className="partnerDiv">
					<h1 className="akb">Your partner in helping patients access the medication they need</h1>
				</div>
			</div>
		</section>
		<section id="gradient-block">
			<div id="quote" className="flex-row"
				data-sal="zoom-in"
				data-sal-duration="600"
				data-sal-delay="200"
				data-sal-ease="ease-out-expo">
				<div>
					<blockquote>
						<p className="blockquote_p"><span>&#x201C; </span>They are extremely easy to work with. I am very thankful for having that support for my patients and them always being available.<span>&#x201d;</span></p>
						<cite>&mdash; <strong>Constance Nicastro-Bowman</strong>, CNP Nurse Practitioner</cite>
					</blockquote>
				</div>
			</div>

			<div id="coverage-circle" className="flex-row">
				<div
					data-sal="slide-up"
					data-sal-duration="600"
					data-sal-delay="100"
					data-sal-ease="ease-out-expo">
					<h2>Coverage solutions<br />made simple</h2>
					<h3>MEDICARE LIS/DUAL ELIGIBLE</h3>
					<ul>
						<li>AURYXIA is widely available through Medicare part D plans<sup>1</sup>
							<ul>
								<li>Provide insurance reimbursement support based on patient's coverage</li>
							</ul>
							<ul>
								<li>Depending on the Medicare Part D insurance plan, a medical exception or prior authorization form may be required</li>
							</ul>
						</li>
						<li>All Medicare full Low-Income Subsidy (LIS)/dual-eligible patients can pay <strong>no more than $11.00 per fill</strong> of AURYXIA</li>
					</ul>
					<h3>COMMERCIAL INSURANCE</h3>
					<ul>
						<li>AURYXIA is covered through the majority of commercial health plans<sup>1</sup>
							<ul>
								<li>With the <a href="https://www.akebiacareshcp.com/auryxia-copay-card.pdf" target="_blank">AURYXIA Copay Card</a>, eligible patients can pay as little as <strong>$0 per fill</strong>*</li>
							</ul>
						</li>
					</ul>
					<h3>UNINSURED/MEDICARE PART D</h3>
					<ul>
						<li><strong>FREE AURYXIA</strong> may be provided to eligible patients<sup>&#x2020;</sup>:
							<ul>
								<li>Who don&rsquo;t have insurance</li>
								<li>Who have Medicare Part D insurance but cannot afford their <br className="desktop"/>copays</li>
								<li>Whose insurance does not cover AURYXIA</li>
							</ul>
						</li>
					</ul>
					<p className="center"><a className="cta akb" href="https://www.akebiacareshcp.com/" target="_blank" rel="noreferrer">FIND SUPPORT OPTIONS</a></p>
				</div>
			</div>

			{/* <div id="prior-auth" className="flex-row">
				<div
					data-sal="zoom-in"
					data-sal-duration="600"
					data-sal-delay="200"
					data-sal-ease="ease-out-expo">
					<img src={ReimbursmentWhite} alt="case manager" />
					<h3>Prior authorizations claims are resolved in a timely&nbsp;manner</h3> 
				</div>
			</div> */}

		</section>

		<section id="akebia-slab">
			<div
				data-sal="slide-up"
				data-sal-duration="600"
				data-sal-delay="200"
				data-sal-ease="ease-out-expo">
				<div>
					<h2 className="akb">AkebiaCares provides eligible patients with coverage support and medicines at no cost</h2>

					<div className="flex-row">
						<div className="icon"><img src={ReimbursementIcon} alt="Artistic representation of a customer service representative speaking into a headset" /></div>
						<div className="content">
							<h3>REIMBURSEMENT HELP</h3>
							<p>One-on-one support for assistance with insurance questions and challenges</p>
						</div>
					</div>

					<div className="flex-row">
						<div className="icon"><img src={PapIcon} alt="Artistic representation of three CKD patients standing together" /></div>
						<div className="content">
							<h3>PATIENT ASSISTANCE PROGRAMS (PAP)</h3>
							<p>Free AURYXIA may be available for eligible patients with Medicare Part D insurance<sup>&Dagger;</sup></p>
							<ul>
								<li>For patients who are uninsured or have other coverage concerns, AkebiaCares offers comprehensive patient services that may be able to help</li>
							</ul>
						</div>
					</div>

					<div className="flex-row">
						<div className="icon"><img src={CopayIcon} alt="Artistic representation of a dollar bill" /></div>
						<div className="content">
							<h3>COPAY CARD</h3>
							<p>Financial assistance for patients with commercial insurance<sup>&#x00A7;</sup></p>
							<ul>
								<li>Visit <a href="https://www.akebiacareshcp.com/" target="_blank">AkebiaCaresHCP.com</a> for the <a href="https://www.akebiacareshcp.com/auryxia-copay-card.pdf" target="_blank">copay card</a></li>
							</ul>
						</div>
					</div>

				</div>

			</div>
		</section>



		<EopCalloutCards
			siteAudience="akb"
			callOneClass={"highlight"}
			callOneLink={"https://akebia.aspnprograms.com/(S(ycaawahbq1wu0ygt24kweoau))/"}
			newTabOne="_blank"
			callOneContent={
				<>
					<h3>Find pharmacies that dispense AURYXIA</h3>
					<p>Search by ZIP code to locate an AURYXIA network specialty pharmacy.</p>
					<a href="https://akebia.aspnprograms.com/(S(ycaawahbq1wu0ygt24kweoau))/" target="_blank" rel="noreferrer" className="cta akb"><span>Get started</span></a>
					<p><a href={FlashcardPDF} className="gradient-link akb" onClick={event => {
						event.stopPropagation();
					}} target="_blank" rel="noreferrer"><span>Download Pharmacy Locator Flashcard</span></a></p>
				</>
			}
		/>

		<section id="footnotes-wrap" className="col-10">
			<div className="flex-row">
				<div>
					<p className="footnote"><span className="dosing_span_margin">*</span>Restrictions may apply. Copay assistance is not valid for prescriptions reimbursed under Medicare, Medicaid, or similar federal or state programs.</p>
					<p className="footnote"><sup className="sup_margin">&#x2020;</sup>Patients with an income of up to 600% Federal Poverty Level (FPL) may be eligible for free AURYXIA. Additional restrictions apply.</p>
					<p className="footnote"><strong>AkebiaCares does not guarantee coverage and/or reimbursement for all patients.</strong></p>
					<p className="footnote"><sup className="sup_margin">&#x2021;</sup>Medicare Part D patients with an annual income of &#x2264;150% of the Federal Poverty Level are eligible for LIS assistance (also called &#x201c;Extra Help&#x201d;).</p>
					<p className="footnote"><sup className="sup_margin">&#x00A7;</sup>Copay assistance is not valid for prescriptions reimbursed under Medicare, Medicaid, or similar federal or state programs.</p>
				</div>
			</div>

		</section>

	</HPPLayout >

)

export default AccessPage
